<template>
  <div class="home">
    <MainSlider />
    <CategoriesNav />
    <RegularSlider />
    <RecommendedCategories />
    <!--
    <LeaveContacts />
    <OurObjects />
    <NewVideo />
    <Feedback />
    <section class="home-news">
      <BlogReadMore>
        <h4 class="home-news__title">{{ _T("@News & Articles") }}</h4>
        <p class="home-news__subtitle">
          {{
            _T(
              "@Everything you need to know about video surveillance from GreenVision",
            )
          }}
        </p>
      </BlogReadMore>
    </section>
    <Wholesale />
    <Fitters />
    <Questions />
    <Description />-->
    <NewsAndArticle />
    <FAQ />
  </div>
</template>

<script setup>
import CategoriesNav from "~~/modules/home/components/CategoriesNav.vue";
import RecommendedCategories from "~~/modules/home/components/recommendedCategories/RecommendedCategories.vue";
import NewsAndArticle from "~~/modules/home/components/newsAndArticle/NewsAndArticle.vue";
import MainSlider from "~~/modules/home/components/mainSlider/MainSlider.vue";
import logoImg from "~~/assets/icons/logo_preview.png";
import RegularSlider from "~/modules/home/components/regularSlider/RegularSlider.vue";
import FAQ from "~~/modules/home/components/faq/FAQ.vue";

const config = useRuntimeConfig();
const route = useRoute();

useSeoMeta({
  title: _T("@Meta title"),
  description: _T("@Meta description"),
  ogUrl: config.public.appUrl + route.path,
  ogImage: logoImg,
});
</script>

<style lang="scss" scoped>
  .home {
    @include flex-container(column, center);

    background-color: white;

    padding: 16px 0 48px 0;
    gap: 32px;
  }
</style>
